import jwtDecode from 'jwt-decode';
import { TOKEN_HEADER } from '../../constants/headers';
import { BASE_API_ROUTE, TEST_ROUTE } from '../../constants/routes';
import { deleteCall, get, post, postFile} from './base';

export function getVolunteers() {
  return get(BASE_API_ROUTE + '/volunteer/get').then((res) => {
    return res;
  });
}

export function postNewsFile(data) {
  return postFile(BASE_API_ROUTE + '/news/new', data).then((res) => {
    return res;
  });
}

export function postNews(data) {
  return post(BASE_API_ROUTE + '/news/new', data).then((res) => {
    return res;
  });
}

export function deleteNews(newsId) {
  return deleteCall(BASE_API_ROUTE + '/news/' + newsId, {}).then((res) => {
    return res;
  });
}

export function getNews() {
  return get(BASE_API_ROUTE + '/news/get').then((res) => {
    return res;
  });
}

export function signIn(data) {
  return post(BASE_API_ROUTE + '/auth/sign-in', {
    email: data.email,
    password: data.password,
  }).then((res) => {
    return res;
  });
}

export function signOut() {
  const jwt = localStorage.getItem(TOKEN_HEADER);
  return deleteCall(BASE_API_ROUTE + '/auth/sign-out', {
    headers: {
      [TOKEN_HEADER]: jwt,
      'Content-Type': 'application/json',
    },
  }).then((res) => {
    return res;
  });
}

export function setJwt(jwt) {
  localStorage.setItem(TOKEN_HEADER, jwt);
}

export function getJwt() {
  return localStorage.getItem(TOKEN_HEADER);
}

export function getCurrentUser() {
  try {
    const jwt = getJwt();
    return jwtDecode(jwt);
  } catch (err) {
    return null;
  }
}
