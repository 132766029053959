import React, { Component } from "react";
import { HashRouter, Redirect, Route, Switch } from "react-router-dom";
import Navbar from "./components/Navbar";
import {
  HOME_ROUTE,
  SIGNIN_ROUTE
} from "./constants/routes";
import Home from "./pages/Home";
import ProtectedRoute from "./components/ProtectedRoute";
import SignIn from './pages/SignIn';
import { getCurrentUser } from "./services/http/authService";

class App extends Component {
  state = {};

  componentDidMount() {
    const user = getCurrentUser();
    this.setState({ user });
  }

  render() {
    const { user } = this.state;
    return (
      <React.Fragment>
        {/* <HashRouter> */}
        {user && <Navbar user={user} />}
        <main className="container-fluid">
          <Switch>
            <ProtectedRoute exact path={HOME_ROUTE} component={Home} />
            <Route path={SIGNIN_ROUTE} component={SignIn} />

            <Redirect from="/" exact to={HOME_ROUTE} />
          </Switch>
        </main>
        {/* </HashRouter> */}
      </React.Fragment>
    );
  }
}

export default App;
