import React, { Component } from "react";
import { Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import logo from "../assets/img/favicon.png";
import { TOKEN_HEADER } from "../constants/headers";
import { HOME_ROUTE } from "../constants/routes";
import { signOut } from "../services/http/authService";

export default class NavBar extends Component {
  onSignOut = () => {
    signOut()
      .then((res) => this.onSignOutSuccessful())
      .catch((err) => this.onSignOutError(err));
  };

  onSignOutSuccessful = () => {
    localStorage.removeItem(TOKEN_HEADER);
    alert("Sign out success");
    window.location = HOME_ROUTE;
  };

  onSignOutError = (error) => {
    alert(error);
    localStorage.removeItem(TOKEN_HEADER);
    window.location = HOME_ROUTE;
  };
  render() {
    return (
      <header id="header">
        <div className="container d-flex">
          <div className="logo mr-auto">
            <h1><span>{this.props.user.name}{" "}{this.props.user.surname}</span></h1>
            {/* <a href={HOME_ROUTE}>
              <img src={logo} alt="" className="img-fluid" />
            </a> */}
          </div>

          <Navbar expand="md">
            <a className="navbar-brand" href={HOME_ROUTE}>
              {/* <Logo /> */}
            </a>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <nav className="nav-menu">
                <ul>
                  <li className="logout" onClick={this.onSignOut}>
                    Odjavi se
                  </li>
                </ul>
              </nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </header>
    );
  }
}
