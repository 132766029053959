import React, { Component } from "react";

export default class Footer extends Component {
  render() {
    return (
      <footer id="footer">
        {/* <div class="footer-top">
          <div class="container">
            <div class="row">
              <div class="col-lg-4 col-md-6 footer-links">
                <h4>Zvanične stranice fondacije:</h4>
                <ul>
                  <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <a href="http://piramidasunca.ba/">
                      Fondacija Arheološki park: Bosanska Piramida Sunca
                  </a>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <a href="http://parkravne2.com/">
                      Park Ravne 2
                  </a>
                  </li>
                </ul>
                <h4>Trip advisor:</h4>
                <ul>
                  <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <a href="https://www.tripadvisor.com/Attraction_Review-g1131946-d13791717-Reviews-Park_Ravne_2-Visoko_Zenica_Doboj_Canton_Federation_of_Bosnia_and_Herzegovina.html">
                      Park Ravne 2
                  </a>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <a href="https://www.tripadvisor.com/Attraction_Review-g1131946-d2230020-Reviews-Bosnian_Pyramid-Visoko_Zenica_Doboj_Canton_Federation_of_Bosnia_and_Herzegovina.html">
                      Bosanska Piramida
                  </a>
                  </li>
                </ul>
              </div>

              <div class="col-lg-4 col-md-6 footer-links">
                <h4>Facebook</h4>
                <ul>
                  <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <a href="https://www.facebook.com/Pyramid.of.the.Sun.Official">
                      Piramida Sunca
                  </a>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <a href="https://www.facebook.com/groups/BosnianPyramidTrail">
                      Bosanska Piramida Trail
                  </a>
                  </li>
                </ul>
                <h4>Instagram</h4>
                <ul>
                  <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <a href="https://instagram.com/bosnianpyramids">
                      Bosanske Piramide
                  </a>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <a href="https://instagram.com/park.ravne2">
                      Park Ravne 2
                  </a>
                  </li>
                </ul>
                <h4>Youtube</h4>
                <ul>
                  <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <a href="https://www.youtube.com/c/BosnianPyramidsTV">
                      Bosanske Piramide TV
                  </a>
                  </li>
                </ul>
              </div>

              <div class="col-lg-4 col-md-6 footer-contact">
                <h4>Kontaktirajte nas</h4>
                <p>
                  Bosna i Hercegovina <br />
                Visoko 71300
                <br />
                Ravne bb <br />
                  <br />
                  <strong>Br tel:</strong> +387 33 259-935 <br />
                  <strong>Email:</strong> info@bosnianpyramidmarathon.com
                <br />
                </p>
              </div>
            </div>
          </div>
        </div> */}

        <div class="container">
          <div class="copyright">
            Copyright 2005- &copy; Fondacija--- “Arheološki park: Bosanska
            piramida Sunca, Archaeological Park: Bosnian Pyramid of the Sun
            Foundation. All rights reserved
        </div>
        </div>
      </footer>
    );
  }
}
